import './App.css'
import { useState, useEffect } from 'react'
import { createClient } from '@supabase/supabase-js'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import axios from 'axios';
import { FaSync, FaCoffee, FaClipboardCheck } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';


const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

export default function App() {
  const [session, setSession] = useState(null);
  const [syncing, setSyncing] = useState(null);
  const [jobComplete, setJobComplete] = useState(null);
  const [userId, setUserId] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState('');

  console.log('syncing: '+syncing);
  console.log('jobComplete: '+jobComplete);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      console.log('session: '+JSON.stringify(session));
      setSession(session);
      if (session) {
        setUserId(session.user.id);
      }
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session) {
        setUserId(session.user.id);
      }
    })

    if (session && !session.provider_id) { handleLogout(); }

    return () => subscription.unsubscribe()
  }, [])


  //think about having simple search box for Q&A
  // check status of sync job
  useEffect(() => {
    const pollJobStatus = async () => {
      console.log('polling job status for job id: '+jobId+' and user id: '+userId);
      if (!jobId || !userId) return;
      try {
        const response = await axios.get(`https://v69bqgxswj.execute-api.ap-southeast-2.amazonaws.com/prod/job-status?job_id=${jobId}&user_id=${userId}`);
        const status = response.data && response.data.status;
        if (status === 'Completed') {
          setJobComplete(true);
        }
  
        if (status !== 'Completed') {
          // If the job is not yet completed, poll again after a delay
          setTimeout(pollJobStatus, 5000); // 5 seconds delay
        }
      } catch (error) {
        console.error('Failed to poll job status:', error);
        // If an error occurred, try again after a delay
        setTimeout(pollJobStatus, 5000); // 5 seconds delay
      }
    };
  
    // Start polling
    pollJobStatus();
  }, [syncing, jobId, userId]);

  function handleSync(event) {
    event.preventDefault();
    console.log('handling Sync: '+JSON.stringify(session));
    const job_id = uuidv4();
    setJobId(job_id);
    axios.post('https://v69bqgxswj.execute-api.ap-southeast-2.amazonaws.com/prod/processnotion', {session: session, job_id: job_id}, {
        headers: {
          'Content-Type': 'application/json'
        }})
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    setSyncing(true);
    setJobComplete(false);
  }

  const handleLogout = async (event) => {
    if (event) event.preventDefault();
    console.log('logging out...')
    const { error } = await supabase.auth.signOut();
    if (error) console.error('Error logging out:', error.message);
  };

  const handleSearch = async (event) => {
    console.log('handling search...');
    event.preventDefault();

    setIsLoading(true);

    // Simulate an API call
    await new Promise((resolve) => setTimeout(resolve, 2000));

    // call search API /space-chat with axios post
    // set result to response
    const response = await axios.post('https://v69bqgxswj.execute-api.ap-southeast-2.amazonaws.com/prod/space-chat', {
      prompt: searchTerm, 
      session: session
    }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    console.log('response from search: '+JSON.stringify(response));
    console.log('--- AI response ---: '+JSON.stringify(response.data.response.choices[0].message.content));
    setResult(response.data.response.choices[0].message.content);
    setIsLoading(false);
  };
  

  return (
    <div>
      {!session ? (
        <div className='background'>
        <div className='container'>
        <>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1rem' }}>
            <h1 style={{color: '#FF96A0'}}>
              Spacechat.me
            </h1>

            <p style={{ textAlign: 'center' }}>
              The best way to  {' '} 
              <span style={{color: '#FF96A0'}}><strong>query your Notion</strong></span> workspace
            </p>
          </div>
        
          <div style={{ width: '60%', margin: '0 auto' }}>
            <Auth
              supabaseClient={supabase}
              appearance={{ theme: ThemeSupa, 
                variables: {
                  default: {
                    colors: {
                      brand: '#FFB6C1',
                      brandAccent: '#FF96A0',
                    },
                  },
                }, }}
              theme="dark"
              providers={['notion']}
              onlyThirdPartyProviders={true}
            />
          </div>
        </>
        </div>
        </div>
      ) : (
        <div className='background-2'>
        <div className='container-2'>
        <div>
        <a href="/logout" onClick={handleLogout} style={{ position: 'absolute', top: 10, right: 10 }}>Logout</a>
        {jobComplete && 
      <div>
        <div style={{ width: '100%', margin: '0 auto', textAlign: 'center' }}>
          <p style={{fontSize: '21px'}}><FaClipboardCheck /> You've successfully synced!</p>
        </div>
      </div>
     }
          {(!syncing || jobComplete) && (
            <div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '1rem' }}>
              <h1 style={{color: '#FF96A0', marginTop: '3rem'}}>
                Spacechat.me
              </h1>

              <p style={{ textAlign: 'center' }}>
                You can {' '} 
                <span style={{color: '#FF96A0'}}><strong>sync your latest Notion data.</strong></span> 
                {' '}This may take a few minutes, depending on the your workspace size.
              </p>
            </div>
            <div style={{ width: '60%', margin: '0 auto', textAlign: 'center' }}>
              <button type="button" className="btn-info" onClick={handleSync}>
                Sync <FaSync />
              </button>
            </div>
            <div style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <form onSubmit={handleSearch} style={{ width: '60%', textAlign: 'center' }}>
                <div style={{ display: 'flex', width: '100%', marginBottom: '1rem' }}>
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    style={{ flex: 1, padding: '0.5rem', fontSize: '1rem' }}
                  />
                  <button type="submit" style={
                    { padding: '0.5rem 1rem', 
                      fontSize: '1rem',
                      backgroundColor: '#FF96A0',
                      color: 'black',
                      border: 'none',
                      transition:'background-color 0.3s ease',
                      cursor: 'pointer' }
                  }>Ask</button>
                </div>
              </form>
              {isLoading ? <p>Thinking...</p> : <p>{result}</p>}
            </div>
          </div>
          )}
        {syncing && !jobComplete && <p style={{fontSize: '21px'}}><FaCoffee /> You can grab some coffee and relax... This may take a few minutes.</p>}
      </div>
      </div>
      </div>
      )}
      
      </div>
  );
}